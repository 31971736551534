(function ($) {
    //Fixed Menu
    $(window).scroll(function () {
      if ($(this).scrollTop() > 100) {
        $('.site-header').addClass("fixed-menu").fadeIn();
      } else {
        $('.site-header').removeClass("fixed-menu");
      }
    });
  
    //Home-title height 100%
    var height = window.innerHeight;
    var width = window.innerWidth;
  
    if (width < 780) {
      var height = height - 125;
    }
  
    $('.home-section-title').css('min-height', height + 'px');
  
  })(jQuery);
  